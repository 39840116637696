import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

const StyledSpan = styled.span`
  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &:after {
    content: "${props => (props.isBlink ? "" : "")}";
    font-size: "16px";
    font-weight: bold;
    animation: ${props => (props.isBlink ? "blink infinite 0.8s" : "")};
  }
`

const Typing = ({
  message = "default",
  cursor = true,
  typeEnd = "",
  className = "",
  speed = 3,
  setIsDone = "",
  sleepTime = 100,
  txtColor = "white"
}) => {
  const [text, setText] = useState("")
  const [textColor, setTextColor] = useState("#dadada")
  const [isBlink, setIsBlink] = useState(true)
  const msgEl = useRef()

  // 指定された間隔でstateを更新する
  useEffect(() => {
    const sleep = delay => new Promise(resolve => setTimeout(resolve, delay))

    // マウント時の処理
    const charItr = message[Symbol.iterator]()
    let timerId

    function showChar() {
      const nextChar = charItr.next()
      if (nextChar.done) {
        // typeEnd()

        // setText(current => current.charAt(0).toUpperCase() + current.slice(1))

        setTextColor(`${txtColor}`)
        if (typeof setIsDone === "function") {
          sleep(sleepTime).then(result => {
            setIsDone(true)
            setIsBlink(false)
          })
        }
        return
      }
      setText(current => current + nextChar.value)
      timerId = setTimeout(showChar, speed)
    }
    showChar()
    // アンマウント時に念のためタイマー解除
    return () => clearTimeout(timerId)
  }, [])

  // レンダリングのたびに表示エリアをスクロールする
  useEffect(() => {
    const el = msgEl.current
    if (el.clientHeight < el.scrollHeight) {
      el.scrollTop = el.scrollHeight - el.clientHeight
    }
  })

  return (
    <span
      className={className + (cursor ? " cursor-blink" : "")}
      style={{ whiteSpace: "pre-line", color: `${textColor}`, fontSize: "26px" }}
      ref={msgEl}
    >
      <StyledSpan isBlink={isBlink} >{text}</StyledSpan>
    </span>
  )
}

export default Typing
