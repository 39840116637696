import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { media } from "../styles/media"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import TypeWriter from "../components/TypeWriter"

import { FaPenFancy } from "react-icons/fa"
import { IconContext } from "react-icons"

import BackImage from "../images/backgroundBlack.png"



const StyledWrap = styled.div`
  width: 100%;
  position: relative;
`
const StyledBack = styled.div`
  position: absolute;
  background: url(${BackImage});
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`

const StyledContents = styled.div`
  padding:50px 5%;
  position:relative;
    ${() => media.tablet(css`
    max-width:600px;
    margin:0 auto;
  `
)}
`
const StyledHead = styled.div`
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:space-around;
  margin-bottom:2.62em;
  position:relative;
  .title{
    width:80%;
    margin:0 auto;
    position:relative;
    h2{
      font-size:1.62em;
      span{
        /* animation:txt 6s ease-in 0s forwards 1; */
      }
    svg{
      animation:penss 6s ease-in 0s forwards 1;
      position:absolute;
    }
    }
    p{
      font-size:.9em;
      padding:.62em 0;
    }
  }
  @keyframes penss{
    0%{transform:translateX(0px) translateY(0px) rotate(0deg);opacity:0;}
    5%{transform:translateX(10px) translateY(1px) rotate(3deg);opacity:1;}
    8%{transform:translateX(40px) translateY(0px) rotate(-5deg);opacity:1;}
    12%{transform:translateX(58px) translateY(-2px) rotate(0deg);opacity:1;}
    16%{transform:translateX(75px) translateY(1px) rotate(4deg);opacity:1;}
    21%{transform:translateX(90px) translateY(3px) rotate(0deg);opacity:1;}
    23%{transform:translateX(100px) translateY(2px) rotate(-8deg);opacity:1;}
    25%{transform:translateX(110px) translateY(-3px) rotate(0deg);opacity:1;}
    26%{transform:translateX(115px) translateY(0px) rotate(5deg);opacity:1;}
    29%{transform:translateX(120px) translateY(0px) rotate(0deg);opacity:1;}
    32%{transform:translateX(140px) translateY(0px) rotate(-10deg);opacity:1;}
    40%{transform:translateX(180px) translateY(0px) rotate(0deg);opacity:0;}
    100%{transform:translateX(300px) translateY(0px) rotate(0deg);opacity:0;}
  }
  @keyframes txt{
    0%{transform:translateX(0px) translateY(0px) rotate(0deg);opacity:0;}
    25%{transform:translateX(3px) translateY(1px) rotate(0deg);opacity:0;}
    26%{transform:translateX(-20px) translateY(0px) rotate(0deg);opacity:0;}
    30%{transform:translateX(0px) translateY(0px) rotate(0deg);opacity:1;}
    100%{transform:translateX(0px) translateY(0px) rotate(0deg);opacity:1;}
  }
`

const StyledBody = styled.div`
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  justify-content:space-between;
  width:100%;
  position:relative;
  .blogList{
    position:relative;
    width:100%;
    margin-bottom:50px;
    &:hover{
      .blogImg .gatsby-image-wrapper{
        transform:scale(1.2,1.2);
        opacity:.6;
      }
    }
    .blogImg{
      width:100%;
      height:180px;
      overflow:hidden;
      .gatsby-image-wrapper{
        width:100%;
        height:100%;
        object-fit:cover;
        transition:.8s;
      }
    }
    p{
      color:${({ theme }) => theme.colors.primary.beige};
      font-size:.8em;
      padding:.62em 0;
    }
    h3{
      font-size:1.32em;
    }
    a{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
  }
`

const IconPen = () => (
  <IconContext.Provider value={{ color: "#c0a77f", size: "25px" }}>
    <FaPenFancy />
  </IconContext.Provider>
)

const texts = [
  `Aleenta Blog`,
]

const Blog = () => {
  const data = useStaticQuery(query);
  const [first, setFirst] = useState(false)

  const jsonld = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": `${data.seo.pagesSeo[5].pageTitle}`,
      "description": `${data.seo.pagesSeo[5].pageDescript}`,
      "publisher": {
        "@type": "ProfilePage",
        "name": `${data.seo.organizeName}`
      }
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.pagesSeo[5].pageUrl}`,
          "name": `${data.seo.pagesSeo[5].pageTitle}`,
          "image": `${data.seo.pagesSeo[5].pageMedia.absolutePath}`
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[5].pageTitle}
        description={data.seo.pagesSeo[5].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[5].pageUrl}
        type='blog'
        imageUrl={data.seo.pagesSeo[5].pageMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack></StyledBack>
        <StyledContents>
          <StyledHead>
            <div className="title">
              <h2><IconPen />
                <span>
                  <TypeWriter message={texts[0]} setIsDone={setFirst} txtColor="white" speed="200" />
                </span></h2>
              <p>アリンタロイヤルリゾートの最新情報など</p>
            </div>
          </StyledHead>
          <StyledBody>
            {data.strapi.nodes.map((blog, index) => (
              <div className="blogList" key={blog.id}>
                <div className="blogImg">
                  <Image fluid={blog.blogMedia.childImageSharp.fluid} alt={blog.blogMediaAlt} />
                </div>
                <p>{blog.blogDate}</p>
                <h3>{blog.blogTitle}</h3>
                <Link to={"/release/" + blog.slug}></Link>
              </div>
            ))}
          </StyledBody>
        </StyledContents>
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    strapi: allStrapiAleentaBlog(sort: {fields: blogDate, order: DESC}) {
      nodes {
        blogDate
        id
        slug
        blogTitle
        blogMediaTitle
        blogContents
        blogMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default Blog
